import React from 'react';
import HandoffPage from 'src/px/HandoffPage';
import { Page } from '../../components';

const SlasherHandoffView = ({}) => {
  return (
    <Page
      context="Slasher"
      progress={0.85}
      metadata={{
        title: 'Slash your premiums',
        description: 'See how much you could save on your current coverage',
        image: '/img/og/slasher.png',
      }}
    >
      <HandoffPage />
    </Page>
  );
};

export default SlasherHandoffView;
